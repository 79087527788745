import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import au from 'date-fns/locale/en-AU';
import { useAuth0 } from '@auth0/auth0-react';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';

import tokenManager from './utils/tokenManager';
import { preloadIconsAsync } from './utils/preloadIconsAsync';
import { useSyncNewUserMutation } from './slices/api';

// ----------------------------------------------------------------------

export default function App() {
	const { getAccessTokenSilently, user } = useAuth0();
	const ifSyncNewUser = user?.logins_count < 3;

	const [syncNewUser, { isLoading, isError, isSuccess }] = useSyncNewUserMutation();

	tokenManager.setAccessTokenSilently(getAccessTokenSilently);

	useEffect(() => {
		if (ifSyncNewUser) {
			syncNewUser();
		}
	}, [syncNewUser, ifSyncNewUser]);

	useEffect(() => {
		async function load() {
			await preloadIconsAsync();
		}

		load();
	});

	if (!ifSyncNewUser) {
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={au}>
				<HelmetProvider>
					<ThemeProvider>
						<ScrollToTop />
						<Router />
					</ThemeProvider>
				</HelmetProvider>
			</LocalizationProvider>
		);
	}

	if (isLoading) {
		return <div>loading....</div>;
	}

	if (isError) {
		return (
			<div>
				Something doesn't look right! Please refresh the page. If error still persists, please contact system admin.{' '}
			</div>
		);
	}

	if (isSuccess) {
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={au}>
				<HelmetProvider>
					<ThemeProvider>
						<ScrollToTop />
						<Router />
					</ThemeProvider>
				</HelmetProvider>
			</LocalizationProvider>
		);
	}

	return <div>Unhandled state.</div>;
}
