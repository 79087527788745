import { loadIcons } from '@iconify/react';

// icons to be preloaded to prevent rendering delays
const icons = ['eva:file-text-outline', 'eva:edit-fill', 'eva:trash-2-outline'];

export const preloadIconsAsync = () => {
	return new Promise((fulfill, reject) => {
		loadIcons(icons, (loaded, missing, pending) => {
			if (pending.length) {
				// Icons are pending, wait for all to load/fail
				//
				// If pending list is not empty, callback will be called
				// again when all icons are either loaded or missing
				return;
			}
			if (missing.length) {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject({
					loaded,
					missing,
				});
			} else {
				fulfill({
					loaded,
				});
			}
		});
	});
};
