import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import convert from 'convert-units';
import _pick from 'lodash.pick';

import { fileMime } from './config';

const Request = {
  // these status can be set from UI
  editableStatus: {
    ADMIN: ['READY_FOR_PAYMENT', 'READY_FOR_SCHEDULE', 'READY_FOR_ASSESSMENT', 'READY_FOR_APPROVAL', 'COMPLETED'],
    SUPPLIER: ['READY_FOR_APPROVAL'],
  },
};

const candidateSchema = {
  firstname: Yup.string().default('').required('Required'),
  lastname: Yup.string().default('').required('Required'),
  employer: Yup.string().default('').nullable(true),
  phone: Yup.string().min(10).max(10).default('').required('Required'),
  email: Yup.string().email('Invalid email').default('').required('Required'),
  addrLine: Yup.string().default('').trim().required('Required'),
  addrState: Yup.string().default('').trim().required('Required'),
  addrSuburb: Yup.string().default('').trim().required('Required'),
  addrPostcode: Yup.string().default('').trim().required('Required'),
};

const fullSchema = Yup.object().shape({
  id: Yup.string().default(''),
  orderNo: Yup.string().default('').nullable(true),
  assessmentDate: Yup.date().nullable(true).default(null),
  status: Yup.string().default('READY_FOR_PAYMENT'),
  supplierId: Yup.string().default('').required('Please select a test centre.'),
  tests: Yup.array().default([]).min(1).required(),
  candidate: Yup.object().shape({
    ...candidateSchema,
  }),
});

Request.fetchSchema = (subset) => {
  if (subset === 'CANDIDATE_PERSONAL_INFO_CONTACT') {
    return Yup.object().shape({
      candidate: Yup.object().shape({
        ..._pick(candidateSchema, ['firstname', 'lastname', 'phone', 'email', 'employer']),
      }),
    });
  }

  if (subset === 'CANDIDATE_ADDRESS') {
    return Yup.object().shape({
      candidate: Yup.object().shape({
        ..._pick(candidateSchema, ['addrLine', 'addrState', 'addrSuburb', 'addrPostcode']),
      }),
    });
  }

  if (subset === 'TESTS') {
    return fullSchema.pick(['tests']);
  }

  if (subset === 'SUPPLIER') {
    return fullSchema.pick(['supplierId']);
  }

  return fullSchema;
};

Request.normalizeAddress = (addrLine, addrSuburb, addrState, addrPostcode) => {
  return `${addrLine} ${addrSuburb} ${addrState} ${addrPostcode}`;
};

Request.isAddressValid = (addrLine, addrSuburb, addrState, addrPostcode) => {
  try {
    fullSchema.validateSyncAt('candidate.addrLine', {
      candidate: {
        addrLine,
      },
    });

    fullSchema.validateSyncAt('candidate.addrSuburb', {
      candidate: {
        addrSuburb,
      },
    });

    fullSchema.validateSyncAt('candidate.addrState', {
      candidate: {
        addrState,
      },
    });

    fullSchema.validateSyncAt('candidate.addrPostcode', {
      candidate: {
        addrPostcode,
      },
    });

    return true;
  } catch (err) {
    return false;
  }
};

Request.ifMarkTestChecked =
  (requestedTests = []) =>
  (test = {}) => {
    return requestedTests.findIndex((t) => t.id.toString() === test.id.toString()) > -1;
  };

Request.resolveCoordinates = (coordinates = {}) => {
  const [lng, lat] = coordinates.coordinates;

  return {
    lng,
    lat,
  };
};

Request.formatAssessmentDate = (assessmentDate) => {
  return assessmentDate ? format(parseISO(assessmentDate), 'dd-MM-yyyy HH:mm') : assessmentDate;
};

Request.formatSupplierDistance = (distanceInMeters) => {
  return `${convert(distanceInMeters).from('m').to('km').toFixed(2)}km`;
};

Request.getReportName = (s3ObjectKey) => {
  return s3ObjectKey.split('/').at(-1);
};

// remove custom cast and use another way - see how setting supplier role in user edit is handled
Request.cast = (params) => {
  const req = Request.fetchSchema().cast(params);
  const isReadyForPayment = req.status === 'READY_FOR_PAYMENT';

  return {
    isReadyForPayment,
    ...req,
  };
};

Request.acceptedReportFileType = [fileMime.pdf, fileMime.doc, fileMime.img].join(', ');

export default Request;
