import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import SettingsCard from '../components/card';

const items = [
  {
    title: 'Manage Testing Centres',
    descr: 'Add, Edit or Delete a testing centres',
    path: '/dashboard/admin/suppliers',
  },
  {
    title: 'Manage Email Template',
    descr: 'Add, Edit or Delete an email template that is used to compose customer emails',
    path: '/dashboard/admin/email-templates',
  },
  {
    title: 'Manage Tests',
    descr: 'Add, Edit or Delete a test',
    path: '/dashboard/admin/tests',
  },
  {
    title: 'Manage Users',
    descr: 'Edit user details',
    path: '/dashboard/admin/users',
  },
  {
    title: 'PlaceHolder Item',
    descr: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit',
  },
];

export default function Admin() {
  const navigate = useNavigate();

  const setPath = path => () => navigate(path);

  const cards = items.map((item, i) => {
    return (
      <Grid item md={3} key={i}>
        <SettingsCard onClick={setPath(item.path)}>
          <CardContent sx={{ height: '75%' }}>
            <Typography variant="h5" component="div">
              {item.title}
            </Typography>
            <Typography variant="body2">{item.descr}</Typography>
          </CardContent>
          <CardActions />
        </SettingsCard>
      </Grid>
    );
  });

  return (
    <>
      <Helmet>
        <title> Admin - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Grid container justifyContent="start" spacing={2}>
        {cards}
      </Grid>
    </>
  );
}
