import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import MainLayout from './layouts/main';

// request
import Requests from './pages/Requests';
import RequestNew from './pages/RequestNew';
import RequestEdit from './pages/RequestEdit';
import RequestView from './pages/RequestView';

// supplier
import Suppliers from './pages/admin/Suppliers';
import SupplierNew from './pages/admin/SupplierNew';
import SupplierEdit from './pages/admin/SupplierEdit';
import SupplierView from './pages/admin/SupplierView';

// email template
// import EmailTemplate from './pages/admin/EmailTemplate';
import EmailTemplates from './pages/admin/EmailTemplates';
import EmailTemplateEdit from './pages/admin/EmailTemplateEdit';
import EmailTemplateNew from './pages/admin/EmailTemplateNew';

// tests
import Tests from './pages/admin/Tests';
import TestNew from './pages/admin/TestNew';
import TestEdit from './pages/admin/TestEdit';

// users
import Users from './pages/admin/Users';
import UserEdit from './pages/admin/UserEdit';

// admin
import Admin from './pages/Admin';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';

import AuthGuard from './components/AuthGuard';
import AuthorizationGuard from './components/AuthorizationGuard';

// ----------------------------------------------------------------------

export default function Router() {
	const routes = useRoutes([
		{
			path: '/dashboard',
			element: <AuthGuard component={DashboardLayout} />,
			children: [
				{ path: 'app', element: <DashboardAppPage /> },
				{
					path: 'requests',
					element: <AuthorizationGuard Component={Requests} everyoneAccess />,
				},
				{
					path: 'requests/new',
					element: <AuthorizationGuard Component={RequestNew} userAccess />,
				},
				{
					path: 'requests/:reqId/edit',
					element: <AuthorizationGuard Component={RequestEdit} everyoneAccess />,
				},
				{
					path: 'requests/:reqId/view',
					element: <AuthorizationGuard Component={RequestView} everyoneAccess backwardRoute="/dashboard/requests" />,
				},
				{
					path: 'admin',
					element: <AuthorizationGuard Component={Admin} adminAccess />,
				},
				{
					path: 'admin/suppliers',
					element: <AuthorizationGuard Component={Suppliers} adminAccess />,
				},
				{
					path: 'admin/suppliers/new',
					element: <AuthorizationGuard Component={SupplierNew} adminAccess />,
				},
				{
					path: 'admin/suppliers/:supplierId/edit',
					element: <AuthorizationGuard Component={SupplierEdit} adminAccess />,
				},
				{
					path: 'admin/suppliers/:supplierId/view',
					element: <AuthorizationGuard Component={SupplierView} adminAccess />,
				},
				{
					path: 'admin/email-templates',
					element: <AuthorizationGuard Component={EmailTemplates} adminAccess />,
				},
				// {
				//   path: 'admin/email-templates/:emailTemplateId/view',
				//   // only allow access if login is admin
				//   element: <AuthorizationGuard Component={EmailTemplate} adminAccess />,
				// },
				{
					path: 'admin/email-templates/:emailTemplateId/edit',
					element: <AuthorizationGuard Component={EmailTemplateEdit} adminAccess />,
				},
				{
					path: 'admin/email-templates/new',
					element: <AuthorizationGuard Component={EmailTemplateNew} adminAccess />,
				},
				{
					path: 'admin/tests',
					element: <AuthorizationGuard Component={Tests} adminAccess />,
				},
				{
					path: 'admin/tests/new',
					element: <AuthorizationGuard Component={TestNew} adminAccess />,
				},
				{
					path: 'admin/tests/:testId/edit',
					element: <AuthorizationGuard Component={TestEdit} adminAccess />,
				},
				{
					path: 'admin/users',
					element: <AuthorizationGuard Component={Users} adminAccess />,
				},
				{
					path: 'admin/users/:userId/edit',
					element: <AuthorizationGuard Component={UserEdit} adminAccess />,
				},
			],
		},
		{
			// Layout Route - A parent route without a path, used exclusively for grouping child routes inside a specific layout.
			element: <AuthGuard component={MainLayout} />,
			children: [
				// an index route is a route that renders on parents path
				// e.g when /dashboard is matched, it shows /dashboard/app's content too
				{ element: <Navigate to="/dashboard/requests" />, index: true },
				{ path: '404', element: <Page404 /> },
				{ path: '*', element: <Navigate to="/404" /> },
			],
		},
		{
			path: '/',
			element: <MainLayout />,
		},
		{
			path: '*',
			element: <Navigate to="/404" replace />,
		},
	]);

	return routes;
}
