import { Chip } from '@mui/material';
import { alpha } from '@mui/material/styles';

const labels = {
	READY_FOR_PAYMENT: 'Ready for Payment',
	READY_FOR_SCHEDULE: 'Ready for Schedule',
	READY_FOR_ASSESSMENT: 'Ready for Assessment',
	READY_FOR_APPROVAL: 'Ready for Approval',
	COMPLETED: 'Completed',
};

const colors = {
	READY_FOR_PAYMENT: '#FF9015',
	READY_FOR_SCHEDULE: '#20C997',
	READY_FOR_ASSESSMENT: '#6CDAEB',
	READY_FOR_APPROVAL: '#0096F1',
	COMPLETED: '#89D240',
};

const Status = ({ status, clickable, onClick }) => {
	if (labels[status] === undefined) {
		return <span />;
	}

	const onHover = {
		':hover': {
			cursor: 'pointer',
			backgroundColor: alpha(colors[status], 0.5),
		},
	};

	return (
		<Chip
			label={labels[status]}
			onClick={onClick}
			clickable={clickable}
			sx={{
				backgroundColor: colors[status],
				color: '#000',
				fontWeight: 'bold',
				width: 150,
				...(clickable ? onHover : {}),
			}}
		/>
	);
};

export default Status;
