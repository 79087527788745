import * as React from 'react';
import { CardContent, CardHeader, Typography, Divider, Grid, Stack, Box } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import Status from '../../../components/request';

const style = {
  divider: {
    borderBottomWidth: 1.5,
  },
};

const RequestDetailCardContent = ({ request }) => {
  const { id, candidate, tests, supplier, status, user } = request;
  const theme = useTheme();

  const renderStatus = Boolean(status);

  return (
    <>
      {id &&
        <>
          <CardHeader title="ID" />
          <Divider sx={style.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {id}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      }
      {renderStatus &&
        <>
          <CardHeader title="Status" />
          <Divider sx={style.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Status status={status} clickable={false} />
              </Grid>
            </Grid>
          </CardContent>
        </>
      }

      {user &&
        <>
          <CardHeader title="Request Owner" />
          <Divider sx={style.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  First Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {user?.firstname}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Last Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {user?.lastname}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Email
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {user?.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Company
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {user?.company}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      }

      <CardHeader title="Candidate Information" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              First Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.firstname}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Last Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.lastname}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader title="Contact" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Phone
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Email
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader title="Address" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.addrLine}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Suburb
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.addrSuburb}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Postcode
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.addrPostcode}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <Typography variant="body1" gutterBottom>
              {candidate?.addrState}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader title="Employer" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" gutterBottom>
              {candidate?.employer}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader title="Testing Centre" />
      <Divider sx={style.divider} />
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h6">{supplier?.name}</Typography>
        </Grid>
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocationOn color="action" />
            <Typography variant="body1">{supplier?.address}</Typography>
          </Box>
        </Stack>
      </CardContent>

      <CardHeader title="Tests" />
      <Divider sx={style.divider} />
      <CardContent>
        <Stack spacing={2}>
          {tests.map((test) => (
            <Stack key={test.id} direction="row" justifyContent="space-between">
              <Typography variant="body1">{test.name}</Typography>
              <Typography variant="body1">{`$${test.price?.toFixed(2) || '0.00'}`}</Typography>
            </Stack>
          ))}
          <Box sx={{ height: 10 }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight="bold">
              Subtotal
            </Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
                {`$${tests.reduce((sum, test) => sum + (test.price || 0), 0).toFixed(2)}`}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight="bold">
              GST (10%)
            </Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
                {`$${(tests.reduce((sum, test) => sum + (test.price || 0), 0) * 0.1).toFixed(2)}`}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight="bold">
              Total (inc. GST)
            </Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }} fontWeight="bold">
                {`$${(tests.reduce((sum, test) => sum + (test.price || 0), 0) * 1.1).toFixed(2)}`}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </>
  );
};

export default RequestDetailCardContent;
