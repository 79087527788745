import {
	Alert,
	FormControl,
	FormLabel,
	Skeleton,
	Typography,
	Card,
	CardContent,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormHelperText,
	Stack,
	Box,
	Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getIn } from 'formik';
import { LocationOn } from '@mui/icons-material';

import { useGetSuppliersQuery } from '../../../slices/api';
import Request from '../../../utils/request';
import Supplier from '../../../utils/supplier';

const Suppliers = ({ formik, requestEdit = false }) => {
	const theme = useTheme();

	const { data: suppliers, isLoading } = useGetSuppliersQuery(
		{
			location: Request.normalizeAddress(
				formik.values.candidate.addrLine,
				formik.values.candidate.addrSuburb,
				formik.values.candidate.addrState,
				formik.values.candidate.addrPostcode,
			),
			testIds: Supplier.formatTestsPayload(formik.values.tests),
		},
		{
			skip: !Request.isAddressValid(
				formik.values.candidate.addrLine,
				formik.values.candidate.addrSuburb,
				formik.values.candidate.addrState,
				formik.values.candidate.addrPostcode,
			),
		},
	);

	const handleChange = (event) => {
		formik.setFieldValue('supplierId', Number(event.target.value));
	};

	if (isLoading) {
		return (
			<Stack spacing={2}>
				<Skeleton variant="rectangular" height={200} />
				<Skeleton variant="rectangular" height={200} />
			</Stack>
		);
	}

	if (!suppliers || suppliers.length === 0) {
		return (
			<Alert severity="error">
				Please contact us at <a href="tel:1300391947">1300 391 947</a> or{' '}
				<a href="mailto:pes@ausrehab.com">pes@ausrehab.com</a> regarding your request.
			</Alert>
		);
	}

	const ifError = Boolean(getIn(formik.touched, 'supplierId') && getIn(formik.errors, 'supplierId'));

	return (
		<FormControl margin="normal" component="fieldset" variant="standard" error={ifError} sx={{ width: '100%' }}>
			{requestEdit && <FormLabel component="legend">Test Centre</FormLabel>}
			{ifError && <FormHelperText>{formik.errors.supplierId}</FormHelperText>}

			<RadioGroup value={formik.values.supplierId} onChange={handleChange}>
				<Stack spacing={2}>
					{suppliers.map((supplier) => (
						<Card
							key={supplier.id}
							variant="outlined"
							sx={{
								'&:hover': {
									borderColor: theme.palette.primary.main,
									cursor: 'pointer',
								},
							}}
							onClick={() => formik.setFieldValue('supplierId', supplier.id)}
						>
							<CardContent>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
									<Stack spacing={2} sx={{ flex: 1 }}>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<FormControlLabel
												value={supplier.id}
												control={<Radio />}
												label={
													<Typography
														variant="h5"
														component="div"
														sx={{
															fontWeight: 700,
															color: theme.palette.text.primary,
														}}
													>
														{supplier.name}
													</Typography>
												}
											/>
											<Typography variant="body1" color={theme.palette.text.secondary} sx={{ ml: 1 }}>
												{Request.formatSupplierDistance(supplier.distanceInMeters)}
											</Typography>
										</Box>

										<Divider />

										<Stack spacing={1}>
											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
												<LocationOn color="action" />
												<Typography variant="body1">{supplier.address}</Typography>
											</Box>

{/* 											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> */}
{/* 												<Phone color="action" /> */}
{/* 												<Typography variant="body1">{supplier.phone}</Typography> */}
{/* 											</Box> */}
{/*  */}
{/* 											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> */}
{/* 												<Email color="action" /> */}
{/* 												<Typography variant="body1">{supplier.email}</Typography> */}
{/* 											</Box> */}
										</Stack>
									</Stack>
								</Box>
							</CardContent>
						</Card>
					))}
				</Stack>
			</RadioGroup>
		</FormControl>
	);
};

export default Suppliers;
